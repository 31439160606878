import { gql } from "@apollo/client";

type UpdateInstructionSettingsMutationVariables = {
	readonly input: {
		readonly catalogueImage: string;
		readonly catalogueEnabled: boolean;
	};
};

const updateInstructionSettingsMutation = gql`
	mutation updateInstructionSettingsMutation(
		$catalogueImage: String!
		$catalogueEnabled: Boolean!
	) {
		updateInstructionSettings(
			catalogueImage: $catalogueImage
			catalogueEnabled: $catalogueEnabled
		) {
			catalogueImage
			catalogueEnabled
			createdAt
			updatedAt
		}
	}
`;

export type { UpdateInstructionSettingsMutationVariables };
export default updateInstructionSettingsMutation;
