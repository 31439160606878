import type { ReactElement } from "react";
import React from "react";

type FileUrlFieldProps = {
	readonly record?: string;
};

function FileUrlField({ record }: FileUrlFieldProps): ReactElement {
	return (
		<a href={record} target="_blank" rel="noreferrer">
			{record}
		</a>
	);
}

export default FileUrlField;
